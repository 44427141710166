import {KTMenu} from './utils/components/menu'
import {KTDialer} from "./utils/components/dialer";
import {KTImageInput} from "./utils/components/image-input";
import {KTScroll} from "./utils/components/scroll";
import {KTScrolltop} from "./utils/components/scrolltop";
import {KTSticky} from "./utils/components/sticky";
import {KTSwapper} from "./utils/components/swapper";
import {KTToggle} from "./utils/components/toggle";
import {InitToastMessages} from "./custom/errormessages/errormessages";
import {KTDrawer} from "./utils/components/drawer";
import {initInfoMap} from "./custom/maps/info-map";
// import $ from 'jquery'

// require('jquery-form')($);

//// import jquery
// window.$ = window.jQuery = require('jquery');
// require('jquery-form');

// include datatables
import 'datatables.net-bs5/css/dataTables.bootstrap5.css';
import "datatables.net";

window.toastr = require('toastr')

window._ = require('lodash')
window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['X-CSRF-TOKEN'] = document.head.getElementsByTagName('meta')['csrf-token'].getAttribute('content');


window.bootstrap = require('bootstrap');

window.swal = require('sweetalert2');

// this should be modified
window.initInfoMap = initInfoMap;

require('leaflet/dist/leaflet.js');
require('leaflet-draw/dist/leaflet.draw.js');

require('fslightbox');

// On document ready
if (document.readyState === 'loading') {
    console.log(document.readyState)
    document.addEventListener('DOMContentLoaded', KTMenu.init);
    document.addEventListener('DOMContentLoaded', KTDialer.init);
    document.addEventListener('DOMContentLoaded', KTDrawer.init);
    document.addEventListener('DOMContentLoaded', KTImageInput.init);
    document.addEventListener('DOMContentLoaded', KTScroll.init);
    document.addEventListener('DOMContentLoaded', KTScrolltop.init);
    document.addEventListener('DOMContentLoaded', KTSticky.init);
    document.addEventListener('DOMContentLoaded', KTSwapper.init);
    document.addEventListener('DOMContentLoaded', KTToggle.init);
    document.addEventListener('DOMContentLoaded', InitToastMessages);
} else if (document.readyState === 'complete') {


} else {
    KTMenu.init();
    KTDialer.init();
    KTDrawer.init();
    KTImageInput.init();
    KTScroll.init();
    KTScrolltop.init();
    KTSticky.init();
    KTSwapper.init();
    KTToggle.init();
    InitToastMessages();



}
