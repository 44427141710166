export var InitToastMessages = function () {
    if (typeof messages === 'undefined' || messages === undefined) {
        console.log("messages not loaded");
        return;
    }
    toastr.options = {
        "closeButton": true,
        "debug": false,
        "newestOnTop": false,
        "progressBar": true,
        "positionClass": "toast-top-right",
        "preventDuplicates": false,
        "onclick": null,
        "showDuration": "100",
        "hideDuration": "3000",
        "timeOut": "5000",
        "extendedTimeOut": "1000",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut"
    };

    if (messages.success !== '') {
        toastr.success(messages.success, 'Success');
    }

    if (messages.error !== '') {
        toastr.warning(messages.error, 'Error');
    }
}
