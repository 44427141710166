export let initInfoMap = function (lat, lon) {
    let center = [lat, lon];
    let map = L.map('mapid');
    let init = () => {
        L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
            {
                id: 'mapbox.streets',
                maxZoom: 17
            }
        ).addTo(map)
        map.setView(center, 15);

        let fGroup = new L.FeatureGroup();
        map.addLayer(fGroup);
        let marker = L.circleMarker(center,
            {
                radius: 3,
                color: '#4fc9da',
                weight: 2,
            }
        )
        marker.addTo(fGroup)
    }
    init();
}
